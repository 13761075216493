
@media only screen and  (max-width: 576px) {
    .container-text{
        padding-top: 10%;
        padding-left: 25%;
        padding-right: 25%;
        color: #343741!important;
        //text-align: justify;
        margin-top: 5%;
        margin-left: 15%;
        margin-right: 15%;
        //background-color: red;
      }
  
      #noti-engagements{
  
          .btnS{
              padding-left: 20%;
          } 
  
          .btnC{
              padding-left: 20%;
          } 
  
          .texto{
  
          white-space: pre;
          margin-left: -100px;
          } 
  
          .alert{
  
            margin-left: 15%;
          } 
  
          .btn-ok{
            margin-left: 1%;
          } 
         
      } 

  

  }
  
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    .container-text{
        padding-top: 10%;
        padding-left: 25%;
        padding-right: 25%;
        color: #343741!important;
        //text-align: justify;
        margin-top: 5%;
        margin-left: 15%;
        margin-right: 15%;
        //background-color: red;
      }
  
      #noti-engagements{
  
          .btnS{
              padding-left: 20%;
          } 
  
          .btnC{
              padding-left: 20%;
          } 
  
          .texto{
  
          white-space: pre;
          margin-left: -100px;
          } 
  
          .alert{
  
            margin-left: 15%;
          } 
  
          .btn-ok{
            margin-left: 10%;
          } 
         
      } 

  
  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .container-text{
        padding-top: 10%;
        padding-left: 25%;
        padding-right: 25%;
        color: #343741!important;
        //text-align: justify;
        margin-top: 5%;
        margin-left: 15%;
        margin-right: 15%;
        //background-color: red;
      }
  
      #noti-engagements{
  
          .btnS{
              padding-left: 20%;
          } 
  
          .btnC{
              padding-left: 20%;
          } 
  
          .texto{
  
          white-space: pre;
          margin-left: -100px;
          } 
  
          .alert{
  
            margin-left: 15%;
          } 
  
          .btn-ok{
            margin-left: 10%;
          } 
         
      } 
  
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .container-text{
        padding-top: 10%;
        padding-left: 25%;
        padding-right: 25%;
        color: #343741!important;
        //text-align: justify;
        margin-top: 5%;
        margin-left: 15%;
        margin-right: 15%;
        //background-color: red;
      }
  
      #noti-engagements{
  
          .btnS{
              padding-left: 20%;
          } 
  
          .btnC{
              padding-left: 20%;
          } 
  
          .texto{
  
          white-space: pre;
          margin-left: -20px;
          } 
  
          .alert{
  
            margin-left: 40%;
          } 
  
          .btn-ok{
            margin-left: 35%;
          } 
         
      } 
   
  
  }
  
  @media only screen and (min-width: 1200px) {
    .container-text{
      padding-top: 10%;
      padding-left: 25%;
      padding-right: 25%;
      color: #343741!important;
      //text-align: justify;
      margin-top: 5%;
      margin-left: 15%;
      margin-right: 15%;
      //background-color: red;
    }

    #noti-engagements{

        .btnS{
            padding-left: 20%;
        } 

        .btnC{
            padding-left: 20%;
        } 

        .texto{

        white-space: pre;
        margin-left: -20px;
        } 

        .alert{

          margin-left: 25%;
        } 

        .btn-ok{
          margin-left: 40%;
        } 
       
    } 
       
  
  }
  