#depositos-garantia {
  p {
    color: rebeccapurple;
  }

  .cursor {
    cursor: pointer;
  }

  .input {
    border-radius: 15px;
  }

  .form-row {
    display: block;
  }

  .form-border {
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
  }

  .container-center {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  #title-page {
    margin-top: 12%;
    margin-bottom: 4%;
  }

  #parent {
    display: block;
    //border: 1px;
    //height: 70vh;
    //width: 98%;
    padding-left: 2%;
    padding-right: 2%;
    //border-style: dotted;
    //border: 5px;

  }


  .marco-recibo {
    //border-width: 5px;
    //border-color: black;
    border-style: ridge ridge ridge ridge;

    background-color: rgb(144, 137, 135);
  }

  .marco-button-recibo {
    //border-width: 5px;
    //border-color: black;
    border-style: ridge ridge none ridge;
    background-color: rgb(86, 82, 82);
  }

  .input-date {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 100%;
    max-height: 40px;
    //border-color: black;

  }

  .input-apply {
    margin-top: 5px;
    //height: 80%;
    width: 80%;
    //max-height: 40px;
    //border-color: black;

  }
  .btn-apply {
    margin-top: 15px;
    //height: 80%;
    //width: 80%;
    max-height: 30px;
    //border-color: black;

  }

  .container-right {
    display: flex;
    text-align: right;
    align-items: right;
    flex-direction: column;

  }


  /*iframe {
    aspect-ratio: 16 / 9;
    width: 100% ;
    height: 80vh;
   margin-bottom: 0px;
  }*/
  /* #document-pdf {
    aspect-ratio: 16 / 9;
    width: 200px ;
    height: 200px;
   margin-bottom: 0px;
   //border:5px;
  }*/

  .button-recibo {
    max-width: 150px;

  }

  .modal-radius {
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    border-radius: 15px !important;
  }

  .lm-Modal.lm-Modal--large .lm-Modal-wrapper {
    max-width: 1200px;
    max-height: 1200px;

  }

}