#form-create-engagements {


  .modal1 {
    max-width: 800px;
    color: #008040;
    position: fixed;
    margin: auto;


    h3 {
      font-size: medium;
    }

    .alert {
      margin-left: 35%;
    }

    .texto {
      color: #008040;
      margin-left: 10%;
    }

    .btn-ok {
      margin-left: 30%;
    }

  }

  #modal2 {
    max-width: 800px;
    color: #008040;
    position: fixed;
    margin: auto;


    h3 {
      font-size: medium;
    }

    .alert {
      margin-left: 35%;
    }

    .texto {
      color: #008040;
      margin-left: 10%;
    }

    .btn-ok {
      margin-left: 30%;
    }

  }


  #modal3 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }


  #modal4 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }

  #modal5 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }

  #modal6 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }

  #form-mensajes {

    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1px;

    .label-input {
      margin-top: 15px;
      font-family: Roboto-Ligth, serif;

    }

    .buttons-navigation {
      margin-top: 15px;
      margin-bottom: 25px;
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 1px;

    }
  }

  h3 {

    font-size: x-large;
  }


  .btnS {
    padding-left: 35%;
  }


  .btnC {
    padding-left: 20%;
  }


  .alert {

    margin-top: 15px;
    margin-left: 150px;

  }

  .btn-ok {


    margin-left: 140px;
  }


  .modalExito {
    max-width: 800px;
    color: #008040;

    position: fixed;
    margin: auto;


    h2 {
      font-size: xx-large;
      color: #008040;
      margin-left: 70px;
    }

  }




  @media only screen and (max-width: 576px) {

    #form-liberty {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;
      width: 100%;
      margin-left: 5%;
      margin-right: 5%;
      //background-color: aquamarine;

      .label-input {
        margin-top: 15px;
        margin-left: 36%;
        font-family: Roboto-Ligth, serif;
        margin-left: -50px;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 10px;

      }


      //Para el motivo
      .dictamen-input {
        padding: 1em .375em .8125em;
        left: 1;
        font-size: 1rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #343741 !important;
        margin-left: 8%;
        margin-top: -1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;


    }

  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {

    #form-liberty {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }


    #form-dictamen {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;
      width: 100%;
      margin-left: 5%;
      margin-right: 5%;
      //background-color: pink;

      .label-input {
        margin-top: 15px;
        margin-left: 36%;
        font-family: Roboto-Ligth, serif;
        margin-left: -50px;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 10px;

      }


      //Para el motivo
      .dictamen-input {
        padding: 1em .375em .8125em;
        left: 1;
        font-size: 1rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #343741 !important;
        margin-left: 8%;
        margin-top: -1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {

    #form-liberty {

      padding-left: 20%;
      padding-right: 10%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;
      width: 100%;
      margin-left: 5%;
      margin-right: 5%;
      //background-color: #008040;

      .label-input {
        margin-top: 15px;
        margin-left: 36%;
        font-family: Roboto-Ligth, serif;
        margin-left: -50px;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 15px;

      }

      //Para el motivo
      .dictamen-input {
        padding: 1em .375em .8125em;
        left: 1;
        font-size: 1rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #343741 !important;
        margin-left: 50px;
        margin-top: -1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;
    }



  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {


    #form-liberty {

      padding-left: 20%;
      padding-right: 17%;
      padding-top: 1%;


      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;
      width: 100%;
      margin-left: 5%;
      margin-right: 5%;
      //background-color: red;

      .label-input {
        margin-top: 15px;
        margin-left: 36%;
        font-family: Roboto-Ligth, serif;
        margin-left: -50px;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 10px;

      }


      //Para el motivo
      .dictamen-input {
        padding: 1em .375em .8125em;
        left: 1;
        font-size: 1rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #343741 !important;
        margin-left: 50px;
        margin-top: -1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;
    }




  }

  @media only screen and (min-width: 1200px) {
    #form-liberty {

      padding-left: 17%;
      padding-right: 30%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 1%;
      padding-right: 1%;
      padding-top: 1%;
      width: 100%;
      margin-left: 45%;
      margin-right: 10%;
      //background-color: blue;

      .label-input {
        margin-top: 15px;
        margin-left: 36%;
        font-family: Roboto-Ligth, serif;
        margin-left: -50px;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 10px;

      }


      //Para el motivo
      .dictamen-input {
        padding: 1em .375em .8125em;
        left: 1;
        font-size: 1rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #343741 !important;
        margin-left: 50px;
        margin-top: -1px;
      }
    }

    .container-create {
      padding-top: 1%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;

    }
  }

  @media only screen and (min-width: 1200px) and (min-width: 1500px) {
    #form-liberty {

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;


      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 1%;
      padding-right: 1%;
      padding-top: 1%;
      width: 100%;
      margin-left: 34%;
      margin-right: 23%;
      //background-color: yellow;

      .label-input {
        margin-top: 15px;
        margin-left: 36%;
        font-family: Roboto-Ligth, serif;
        margin-left: -50px;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 10px;

      }


      //Para el motivo
      .dictamen-input {
        padding: 1em .375em .8125em;
        left: 1;
        font-size: 1rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #343741 !important;
        margin-left: 50px;
        margin-top: -1px;
      }
    }


    .container-create {
      padding-top: 1%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;

    }


  }


  .condition-input {
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #919191;
    background-color: #e6e6e6;
    color: #343741 !important;
    margin-top: -2px;
    margin-bottom: 1px;
  }



}