#Create-Auditoria {
    padding-left: 8%;
    padding-right: 8%;
  
    .form-control{
      height: 50%;
      
    }
   
    #table-Auditoria {
       
            width: 90%;
            align-items: center;
            position: relative;
            justify-content: center !important;
            align-content: center !important;
            margin-top: auto;
            margin-left: 5%;
        
    }
    .boton{
        width: 80%;
        align-content: center !important;
        margin-left:35%;
        margin-right: 35%;
    }

  .Rojo{
    color: #D32F2F;
  }
  
 

  .Verde{
    color:#008040;
  }
  
    @media only screen and (min-width: 600px) {
      #Auditoria {
        padding-left: 2%;
        padding-right: 2%;
      }
      .boton{
        width: 30%;
        align-content: center !important;
        margin-left:35%;
        margin-right: 35%;
    }
      @media (min-width: 768px) and (max-width: 1100px) {
        .lm-Table-wrapper {
          overflow-x: scroll;
        }
        .boton{
          width: 40%;
          align-content: center !important;
          margin-left:35%;
          margin-right: 35%;
      }
      }
      @media (min-width: 1100px) {
        .lm-Table-wrapper {
          overflow-x:inherit ;
        }
      }
  
  
    }
}
  
  