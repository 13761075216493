@import "../../../assets/css/colors";
#warranties-review{
  .card{
    margin-bottom: 12px;
  }
  #filters-review-warranty{
    .btn-outline-dark{
      font-family: Roboto-Regular;
      border-color: #919191!important;
      color: #343741!important;

    }
  }

  $paddingColumns: 12px;
  $colorSeparatorColumns: #F5F5F5;
  .pendient{
    padding-left: $paddingColumns;
    padding-right: $paddingColumns;
    border-right: 2px solid $colorSeparatorColumns;
    .card{
      border-left: 7px solid #E6E6E6;
    }
    .card-draggable{
      cursor:move;
    }

  }

  .revision{
    padding-left: $paddingColumns;
    padding-right: $paddingColumns;
    border-right: 2px solid $colorSeparatorColumns;
    .card{
      border-left: 7px solid $colorWarning;
    }
    .success, .error{
      padding-top: 10px;
    }
    .dropdown-more-options{
      background-color: transparent!important;
      border: none!important;
      position: absolute!important;
      right: 0px;
      top: 0px ;
    }
  }

  .finish{
    padding-left: $paddingColumns;
    padding-right: $paddingColumns;
    .card{
      border-left: 7px solid $colorSuccess;
    }
    .success, .error{
      padding-top: 10px;
    }
  }
  #panel-review{


    .title{
      font-family: Roboto-Regular;
      margin-bottom: 15px;
      font-size: 21px;
    }
    .card-title{
      padding-bottom: 10px;
    }
    .card-footer{
      background-color: transparent;
      margin-top: 25px;
      font-family: Roboto-Bold;
    }
    a { color: inherit; }
    a:hover { color: #343741; }
  }

  @media only screen and (max-width: 768px) {
    #btn-search-principals{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

}
