@import "../../../assets/css/colors";
#warranty-comparison {
  padding-left: 6%;
  padding-right: 6%;
  .cursor {
    cursor: pointer;
  }
  .title {
    font-weight: bold;
  }

  ul{
    list-style: none;
    margin-bottom: 0;
  }
}
