#acordion-truncate{
  background: none;
  background-color: transparent !important;
  border-color: transparent !important;
    .card-header {
      background: none;
      border: none;
      background-color: transparent !important;
      cursor: pointer;
    }
    .card-body{
    cursor: pointer;
  }



}