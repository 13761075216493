
@media only screen and  (max-width: 576px) {
    .container-text{
      padding-top: 25%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;
     
    }

 .container-text-client{
     
      color: #343741!important;
      text-align: justify;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 25%;
     
    
    }


    #noti-engagements-client{

      .btnS{
          padding-left: 25%;
      } 
  
      .btnC{
        margin-left: 1%;
      } 
      }

  }
  
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    .container-text{
      padding-top: 18%;
      color: #343741!important;
      text-align: justify;
      padding-left: 5%;
    }

    .container-text-client{

      color: #343741!important;
      text-align: justify;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 25%;
      //background-color: blue;
    
    }

    #noti-engagements{

        .btnS{
            padding-left: 35%;
        } 

        .texto{

          padding-left: 20%;
        } 

        .alert{

          padding-left: 22%;
        } 

        .btn-ok{
          margin-left: 420px;
        } 
       
    } 

    #noti-engagements-client{

      .btnS{
          padding-left: 25%;
      } 
  
      .btnC{
        margin-left: 1%;
      } 
      }
  
  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .container-text{
      padding-top: 15%;
      color: #343741!important;
      text-align: justify;
      padding-left: 15%;
    }

    .container-text-client{
     
      color: #343741!important;
      text-align: justify;
      padding-left: 20%;
      padding-right: 1%;
      padding-top: 25%;
      //background-color: red;
    
    }

    #noti-engagements{

        .btnS{
            padding-left: 20%;
        } 
  
        .texto{

          padding-left: 20%;
        } 

        .alert{

          padding-left: 22%;
        } 

        .btn-ok{
          margin-left: 420px;
        } 
       
    } 
   
    #noti-engagements-client{

    .btnS{
        padding-left: 25%;
    } 

    .btnC{
      margin-left: 20%;
    } 
    }
  
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .container-text{
      padding-top: 12%;
      color: #343741!important;
      text-align: justify;
      padding-left: 20%;
    }

    .container-text-client{
     
      color: #343741!important;
      text-align: justify;
      padding-left: 15%;
      padding-right: 1%;
      padding-top: 20%;
      //background-color: aqua;
    }
   

    #noti-engagements-client{

      .btnS{
          padding-left: 28%;
      } 
  
      .btnC{
        margin-left: 20%;
      } 
      }

  }
  
  @media only screen and (min-width: 1200px) {
    .container-text{
      padding-top: 10%;
      color: #343741!important;
      text-align: justify;
      padding-left: 25%;
    
    }

    .container-text-client{

      color: #343741!important;
      text-align: justify;
      padding-left: 25%;
      padding-right: 1%;
      padding-top: 10%;
      //background-color: blueviolet;
    }

    #noti-engagements{

        .btnS{
            padding-left: 20%;
        } 

        .btnC{
            padding-left: 20%;
        } 

        .texto{

          padding-left: 20%;
        } 

        .alert{

          padding-left: 22%;
        } 

        .btn-ok{
          margin-left: 420px;
        } 
       
    } 

    #noti-engagements-client{

      .btnS{
          padding-left: 29%;
      } 
  
      .btnC{
        margin-left: 18%;
      } 
      }
       
  
  }
  