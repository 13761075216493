@import "../../../assets/css/colors";
.principal-detail{
  padding-bottom: 45px;
  #seccion-principal{
    margin-bottom: 35px;
  }
  #bread-cumb , #header-principal{
    margin-left: 3%;
    margin-right: 3%;
  }

  .accordion{
    padding-left: 0px;
    padding-right: 0px;
  }
  h5{
    font-family: Roboto-Regular, Arial, serif;
  }
  .comments{
    cursor: pointer;
    color: #1A1446;
  }

  #comentarios-principal{
    display: none;
  }

  #acordion-principal{
    @media screen and (min-width: 480px){
      .header-acordion {
        padding-left: 35px;
        padding-right: 35px;
      }
    }

    .lmig-Accordion-list-listItem-header-caret{
      position: absolute;
      right: 55px;
      width: 34px;
      height:34px;
      cursor: pointer;
    }
    .lmig-Accordion-list-listItem-header-caret:hover{
      background-color: rgb(245,245,245	);

      border-radius: 50%;
    }
  }

  .accordion{
    margin-top: 25px;
    .btn-link{
      font-family: Roboto;
      font-weight: 700;
      font-size: 1.5rem;
      text-align: inherit;
      line-height: 1.2em;
      color: #343741;
    }
    .card{
      word-wrap: normal!important;
    }
    .card-body{
      padding: 0px!important;
    }
  }
  .header-acordion{
    cursor: pointer;
  }
  .acordion-content{
    transition: 0.5s;
  }


}