@import "../../../assets/css/colors";
.warranty{
  #header-warranty{
    padding-top: 22px;
  }
  .create-warranty{
    padding-left: 8%;
    padding-right: 8%;
  }

  #form-liberty{
    padding-top: 3%;
    .label-input{
      margin-top: 15px;
      font-family: Roboto-Ligth, serif;
    }
    .upload-file{
      margin-top: 15px;
      font-family: Roboto-Ligth, serif;
      margin-left: 0px!important;
      margin-right: 0px!important;
    }

    .buttons-navigation{
      margin-top: 15px;
      margin-bottom: 25px;
    }

  }

  @media only screen and (min-width: 992px) {
    #form-liberty{
      padding-left: 17%;
      padding-right: 17%;
    }

  }

  .expedient-files{
    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      cursor: pointer;
      display: inline-block;

    }

  }
  .spinner-uploading-file{
    color: $colorAcent!important;
  }
  .list-obligors{
    width: 100%;

    .list{
      background-color: white;
      max-height: 180px;
      overflow-y: auto;
      margin-bottom: 10px;

      .rfc{
        font-family: Roboto-Bold;
        font-size: 11px;
        text-align: center;
      }
      .name{
        font-family: Roboto-Regular;
        font-size: 12px;
        border-left: 1px solid black;
        text-align: left;

      }
    }

  }
  #clients-exclusive{

    .badge-light{
      margin-bottom: 5px;
      margin-right: 3px;
    }
    .list-obligors{
      position: absolute;
      z-index: 3;
      width: 96%!important;
      font-size: 1rem;
      -webkit-transition: opacity .1s ease-out 0s;
      transition: opacity .1s ease-out 0s;
      border-radius: 3px;
      text-overflow: ellipsis;
      cursor: pointer;
      outline: none;
      border: 1px solid #919191;
      background-color: #919191;

      .list-group-item{
        font-family: Roboto-Regular;
        cursor: pointer;
        background-color: rgba(145, 145, 145, 1);
        color: white;
      }

      .list-group-item:hover{
        background-color: #0061F5;
        color: white;
      }

    }
  }
  #idClave{
    margin-top: 5px;
  }
  #importe_maximo{
    margin-bottom: 10px;
  }
  .recommned-percent{
    font-family: Roboto-Ligth, Arial, Serif;
    font-size: 12px;
  }
  .close-icon{
    cursor: pointer;
  }
}