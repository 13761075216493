@import "../../../assets/css/colors";


#engagements{
  
  .engagements-container{
    padding-left: 8%;
    padding-right: 8%;

    .bg-danger{
      background-color: #D32F2F!important;
    }
    .bg-success{
      background-color: #008040!important;
    }
    .bg-warning{
      background-color: #FFD000!important;
    }
    .card{
      margin-bottom: 20px;
    }
  }
  .cards-percentage{
    .card-title{
      font-family: Roboto-Regular,serif;
      border-bottom: 4px solid #1A1446;
      padding-bottom: 15px;
    }
  }
  #card-warranties{
    margin-top: 25px;
  }
  .item-warranty{
    padding-top: 3%;
    padding-bottom: 3%;

    h4{
      font-family: Roboto-Ligth, serif;
      color: #06748C;
      font-size: small;
    }
    .amount{
      font-family: Roboto-Regular, Arial;
      font-size: 22px;
    }
    Badge{
      margin-top: 15px;

    }
    .see-details{
      padding-top: 19px;
      a{
        color: #28A3AF!important
      }

    }
  }
  .status-fianza{
    font-family: Roboto-Bold, Arial;
  }

  .name-agent{
    padding-top: 25px;
    padding-right: 15px;
    text-align: right;
    width: 225px;
    font-family: Roboto-Bold,Arial, Serif;
  }
  .list-clients{
    max-height: 200px;
    overflow-y: auto;
  }


  h4{
    font-family: Roboto-Ligth, serif;
    font-size: 20px;
    color: gray;
    text-align: inherit;
    text-anchor: middle;

  }

  #bloqueo-porcentaje{
    font-family: Roboto-Ligth, serif;
    color: rgb(6, 116, 140);
    font-size: 20px;
    text-align: inherit;
    text-anchor: middle;


  }

  .block{

    font-size: medium;
    font-weight: bold;
  }


  .buttoms{
    margin-top: 25px;
    margin-bottom: 25px;

  }

 

}


.autocomplete-wrapper-agent {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;

}

.autocomplete-wrapper-agent > div {
  width: 100%;
  cursor: pointer;
 
}

.autocomplete-wrapper-agent input {
  border-radius: 3px;
  border: 1px solid  #919191;
  padding: 12px 15px;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  padding: 1em .375em .8125em;
}


.autocomplete-wrapper-agent input:hover {
  border: 1px solid #28a3af;
  padding: 12px 15px;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  padding: 1em .375em .8125em;
 
}

.autocomplete-wrapper-agent input:focus {
  border-color: #28a3af;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.autocomplete-wrapper-agent .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: scroll;
  background-color: #ffffff;
  border: 1px solid  #28a3af;
  border-top: none;
  cursor: pointer;
  border-radius: 3px;
  position: absolute;
  z-index: 3;
  font-size: 1rem;
  -webkit-transition: opacity .1s ease-out 0s;
  transition: opacity .1s ease-out 0s;
  border-radius: 3px;
  text-overflow: ellipsis;

  outline: none;

}

.autocomplete-wrapper-agent .item  {
  display: block;
  cursor: pointer;
  font-size: 15px;
  color: #919191;
  padding: 1px;
  cursor: pointer;
  
}

.autocomplete-wrapper-agent .item.selected-item {
  background-color: #28a3af;
  color: #FAFBFC;
  cursor: pointer;
}

.autocomplete-wrapper-agent .item:hover {
  background-color:  #28a3af;
  color: #FAFBFC;
  cursor: pointer;
}
