#bajas-reclamaciones {
  #title-page {
    text-align: center;
    //margin-top: 10%;
    margin-bottom: 4%;
  }

  .cursor {
    cursor: pointer;
  }

  .container-center {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .badgeYellow {
    border-radius: 15px;
    background: rgba(237, 243, 36,0.5);
  }
  .badgeOrange {
    border-radius: 15px;
    background: rgba(184, 93, 0,0.5);
  }
  .badgeGreen {
    border-radius: 15px;
    background: rgba(0, 128, 64,0.5);
  }
  .badgeBlue {
    border-radius: 15px;
    background: rgba(0, 97, 245,0.5);
  }
  .badgeGray {
    border-radius: 15px;
    background: rgba(112, 112, 112,0.5);
  }
}