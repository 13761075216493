#modal-lock-principal{
  padding-top: 10px;
  padding-bottom: 80px;
  h4{
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .options-block{
    margin-bottom: 20px;
  }
  .list-group-item{
    cursor: pointer!important;
  }
  .list-reject{
  //  min-height: 325px;
  }
  .add-description{
    margin-top: 5px;
    margin-bottom: 4px;
    font-size: 15px;
    color: #707070;
  }
  #buttons{
    margin-top: 30px;
  }
}