#Auditoria {
    padding-left: 12%;
    padding-right: 12%;
  
   
    #table-Auditoria {
        .tb {
            width: 250px;
            align-items: center;
            position: relative;
            justify-content: center !important;
            align-content: center !important;
            margin-top: auto;
        }
    }
     
    .btn-audit{

      cursor: pointer;
    }  
  
    @media only screen and (min-width: 600px) {
      #Auditoria {
        padding-left: 2%;
        padding-right: 2%;
      }
      @media (min-width: 768px) and (max-width: 1100px) {
        .lm-Table-wrapper {
          overflow-x: scroll;
        }
      }
      @media (min-width: 1100px) {
        .lm-Table-wrapper {
          overflow-x:inherit ;
        }
      }
  
  
    }
}