#polizas-rh{

  .thumbs {
    text-align: center;
  }

  .textArea {
    border-radius: 10px;
  }

  .form-row {
    display: block;
  }

  #content-page {
    padding-top: 6%;
  }

  #tbl-moduloRH {
    margin-top: 3%;
  }

  .container {
    display: flex;
    text-align: center;
    align-items: stretch;
    flex-direction: column;
  }

  .container-center {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  .modalPolizas {
    align-items: center;
    text-align: center;
  }

  .cursor{
    cursor: pointer;
  }

  #title-page {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 4%;
  }



  #search{
    display: flex;
    align-items:center;
    align-content: flex-start !important;
    border: 0.5px solid lightgray;
    padding: 3px;
    width: 350px;
    height: 40px;
    border-radius: 10px;

    #input-search{
      border: none;
      outline: none;
      background: transparent;
      width: 315px;
    }
  }

  #boton-agregar-poliza {
    display: flex;
    align-items: center;
  }

  #botones-aceptar-poliza {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
    text-align: center;
  }

  #botones-autorizar-poliza {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
  }


  #botones-rechazar-poliza {
    display: flex;
    margin-top: 2%;
    margin-bottom: 1%;
    text-align: center;
  }

  #boton-upload {
    margin-top: 10%;
  }

  #icono-envio-exito {
    background: teal;
    padding: 4px;
    font-size: xx-large;
    border-radius: 50%;
  }

  #lblFile {
    text-align: center;
  }

  .input-wrapper{
    position: relative;
  }

  #lbl-pagina {
    text-align: center;
  }


  .thumbs {
    text-align: center;
    align-items: center;
  }


  //Prueba DnD
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  #form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .form-border {
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
  }


  .dropdown-menu {
    min-width: 1rem;
  }

  .miDropdown{
    position: inherit !important;
  }

  .dropdown-item{
    padding: 0.20rem 0.8rem;
  }

  .table-head{
    text-align: center;
  }




}
