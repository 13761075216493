#Create-Auditoria {
    padding-left: 8%;
    padding-right: 8%;
  
    .form-control{
      height: 50%;
      
    }
   
    #table-Auditoria {
        .tb {
            width: 100px;
            align-items: center;
            position: relative;
            justify-content: center !important;
            align-content: center !important;
            margin-top: auto;
        }
    }
    #cards-idcartas {
        align-items: center !important;
        align-content: center !important ;
        height: 250px;
        cursor: pointer;
        padding: 0px 0;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.8);
        border: none;
        border-radius: 10px;
  
        #alert_up {
          padding-top: 3%;
          padding-left: 36%;
        }
  
        h3 {
          align-content: center;
          align-items: center;
        }
  
        .global-class-name {
          color: red;
        }
  
        p {
          color: rgb(149, 149, 149);
        }
  
        a {
          color: rgb(149, 149, 149);
        }
  
        #file-upload-input {
          position: absolute;
          display: flex;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          outline: none;
          opacity: 0;
          cursor: pointer;
        }
        #image-upload-wrap {
          position: absolute;
          align-content: center !important;
          align-items: center !important;
          height: 100%;
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
        }
        #image-upload-wrap:hover {
          background-color: transparent;
          border: 4px dashed transparent;
        }
        #text-information {
          margin-top: 30px;
          text-align: center;
        }
        #btn-archivo{
          margin-top: 1%;
        }
      }

      .Modal-excel{
        width: 100%;
        height: 100%;
        border: none;
        .iframe-pdf {  
          display: block;       
          /* iframes are inline by default */  
          background: #000; 
          border: none;         
          /* Reset default border */  
             
          width: 98%;
          height:100vh;
          /* Viewport-relative units 600px*/  
         
          padding-left: 2%;  
          padding-right: 2%;}
      }
    #image-upload-wrap {
        position: absolute;
        align-content: center !important;
        align-items: center !important;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }
      #image-upload-wrap {
        background-color: transparent;
        border: 4px dashed transparent;
      }
      .lm-Modal .lm-Modal-wrapper .lm-Modal-title {
        margin: 0;
        text-align: R;
        color: #343741;
      }
  
      input[type="date"]:required:invalid::-webkit-datetime-edit {
        color: transparent;
      }
      input[type="date"]:focus::-webkit-datetime-edit {
        color: black !important;
      }
     
      .Rojo{
        color: #D32F2F;
      }
      
   
    
      .Verde{
        color:#008040;
      }
    
  
    @media only screen and (min-width: 600px) {
      #Auditoria {
        padding-left: 2%;
        padding-right: 2%;
      }
      @media (min-width: 768px) and (max-width: 1100px) {
        .lm-Table-wrapper {
          overflow-x: scroll;
        }
      }
      @media (min-width: 1100px) {
        .lm-Table-wrapper {
          overflow-x:inherit ;
        }
      }
  
  
    }
}
  
  