@import "../../../assets/css/colors";
#modal-expedient-identification{

  .status-expedient{
    margin-top: 5px;
  }

  .open-expedient{
    cursor: pointer;
  }
  .card{
    margin-top: 4px;
  }
  .card-header{
    background-color: $colorPrimaryYellow!important;
    color: $primaryText;
    font-family: Roboto-Bold,Arial;
    font-size: 10pt!important;
    padding-top:8px ;
    padding-bottom:5px ;

    .ico-add{
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;

    }
  }
  .card-body {
    padding-top:10px;
    padding-bottom:10px;
  }

  .draggable-true{
    cursor:move;
  }
  .draggable-false{
    cursor:no-drop;
  }

  .name-document{
    font-family: Roboto-Regular,Arial;
    font-size: 10pt!important;
  }


  .actions-expedient{
    .btn{
      background-color: transparent;
      border-color: transparent;
    }
  }
  .drop-area{
    background-color: #F5F5F5;
    font-size: 12px;
  }
  .expedient-files{
    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      cursor: pointer;
      display: inline-block;

    }

  }
  @media only screen and (max-width: 720px) {
    .actions-expedient{
      margin-top: 18px;
      background-color: #F5F5F5;
    }

  }
  .list-reject{
    min-height: 250px;
  }

  .lm-Modal .lm-Modal-wrapper .lm-ButtonClose {
    position: absolute;
    top: 0;
    right: 18px;
    left: auto;
  }

}