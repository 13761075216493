#consult-query
 {
  background-color: #ffff;
  h6 {
    font-size: 5px;
    color: #c0bfc0;
    justify-content: center !important;
  }
  
  p {
    font-family: Roboto-Ligth, Arial, Serif;
    text-align: justify;
    size: 2rem;
    margin-left: 25px;
    color: #4f4e4e;
    cursor: pointer;
    margin-top: 5px;
  }
  
  
  .container {
    margin-top: 4%;
  
  #form-admin {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    border-radius: 10px;
    align-items: center !important;
    align-content: center !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  
  
  
    .input-passad{
      font-size: 2rem;
      -webkit-transition: opacity .1s ease-out 0s;
      transition: opacity .1s ease-out 0s;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      border-radius: 3px;
      text-overflow: ellipsis;
      align-content: center !important;
      width: auto!important;
      height: 50px;
      border: 1px solid #343741;
      background-color: #E6E6E6 !important;
      color: #ffffff!important;
      margin-left: 3px;
      margin-right: 3px;
  
  
  
      #ico-show-psw, #ico-hidden-psw{
        .img-fluid{
          margin-top: 30%;
          margin-left: 80%;
        }
      }
      .input-user-name{
        background-color: transparent!important;
        border: none!important;
      }
    
      
    
      @media only screen and (min-width: 1104px) {
        .col-md-2{
          text-align: center;
        }
    
      }
    
      
    
    }
  
    #user_config{
      background-color: #343741;
    }
  
    .input-confirm-passd{
      font-size: 2rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    align-content: center !important;
    width: auto!important;
    height: 50px;
    border: 1px solid #343741;
    background-color: #E6E6E6;
    color: #ffff!important;
    margin-left: 3px;
    margin-right: 3px;
  
    #ico-show-psw, #ico-hidden-psw{
      .img-fluid{
        margin-top: 30%;
        margin-left: 80%;
      }
    }
  
    .input-user-name{
      background-color: transparent!important;
      border: none!important;
    }
      
    }
  
   
  
  
  #buttons{
    align-items:center !important;
    align-content: center !important;
    margin-left: 25%;
    margin-right: 20%;
  }
  #cardss {
    position: relative;
    padding: 0px 0;
    box-shadow: 0 0 4px 0 rgba($color: #C0BFC0, $alpha: 0.6);
    border: none;
    border-radius: 10px;
    width: 60%;
  }
  
  
  #cardss:hover {
    box-shadow: 0 0 4px 0 rgba($color: #28A3AF, $alpha: 0.8);
  }
  
  #modal-contra{
    align-items: center !important;
    align-content: center !important;
    padding-left: 20%;
  
  
  }
  
  h5{
    color: #008040;
  }
  
  
  
  #form-admin {
    .lm-Heading {
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    #forms{
      padding-left: 2%;
      padding-right: 2%;
    }
   
  }
  @media (min-width: 576px) and (max-width: 768px){
    #forms{
      padding-left: 2%;
      padding-right: 2%;
    }
   
  }
  @media (min-width: 768px) and (max-width: 992px)  {
    #forms{
      padding-left: 10%;
      padding-right: 10%;
    }
   
  }
  @media (min-width: 992px)  {
    #forms{
      padding-left: 25%;
      padding-right: 25%;
    }
    
  }
  }
}

