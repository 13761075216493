#info-engagements{




@media only screen and  (max-width: 576px) {
 
  .info{
    //color: green;
    color:  #343741!important;
  }
  
  .infoPequeño{
    //color: green;
    color:  #343741!important;
    margin-left: 10px;
  
  } 
} 

@media only screen and (min-width: 576px) and (max-width: 768px) {

  .info{
    //color: red;
    color:  #343741!important;
  }
  
  .infoPequeño{
    //color: red;
    color:  #343741!important;
    margin-left: 10px;
  
  } 
} 

@media only screen and (min-width: 768px) and (max-width: 992px) {

  .info{
    //color: blue;
    color:  #343741!important;  
  }
  
  .infoPequeño{
    //color: blue;
    color:  #343741!important;
    margin-left: 30px;
  } 
} 


@media only screen and (min-width: 992px) and (max-width: 1200px) {

  .info{
    //color: purple;
    color:  #343741!important;
  }
  
  .infoPequeño{
    //color: purple;
    color:  #343741!important;
    margin-left: 30px;
  } 
} 

@media only screen and (min-width: 1200px) {

  
.info{
  //color: brown;
  color:  #343741!important;
}

.infoPequeño{
  //color: brown;
  color:  #343741!important;
  margin-left: 30px;

} 


}

@media only screen and (min-width: 1200px)  and (min-width: 1500px)  {

    
.info{
  //color: darkcyan;
  color:  #343741!important;
}

.infoPequeño{
  //color: darkcyan;
  color:  #343741!important;
  margin-left: 50px;
} 

} 

}

