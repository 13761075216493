#create-line-afianzamiento {
  padding-bottom: 10px;
  #buttons-linea {
    padding-top: 10px;
  }


  @media (max-width: 768px) {
    #buttons-linea{
      .col-md-6{
        padding-bottom: 20px;
      }

    }
  }
  @media (min-width: 768px) {
    #buttons-linea{
      .col-md-6{
        padding-bottom: 10px;
      }

    }
  }
}