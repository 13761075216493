#delete-linea-afianzamiento{
  padding-bottom: 15px;
  #buttons-linea {
    padding-top: 20px;
  }


  @media (max-width: 768px) {
    #buttons-linea{
      .col-md-6{
        padding-bottom: 20px;
      }

    }
  }
}