#authorize-engagements {


  .modalExito {
    max-width: 800px;
    color: #008040;

    position: fixed;
    margin: auto;


    h2 {
      font-size: xx-large;
      color: #008040;
      margin-left: 70px;
    }

  }

  #modal2 {
    max-width: 800px;
    color: #008040;
    position: fixed;
    margin: auto;


    h3 {
      font-size: medium;
    }

    .alert {
      margin-left: 35%;
    }

    .texto {
      color: #008040;
      margin-left: 10%;
    }

    .btn-ok {
      margin-left: 30%;
    }

  }


  .modal1 {
    max-width: 800px;
    color: #008040;
    position: fixed;
    margin: auto;


    h3 {
      font-size: medium;
    }

    .alert {
      margin-left: 35%;
    }

    .texto {
      color: #008040;
      margin-left: 10%;
    }

    .btn-ok {
      margin-left: 30%;
    }

  }

  #modal3 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }


  #modal4 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }

  #modal5 {
    max-width: 800px;
    color: #343741 !important;
    position: fixed;
    margin: auto;



    h2 {
      font-size: large;
      color: #343741 !important;
    }

    .info {
      margin-left: 35%;
    }

    .texto {
      color: #343741 !important;
      font-size: large;
      margin-left: 15%;
      margin-right: 15%;
      margin-top: 10%;
      margin-bottom: 6%;

    }

    .btn-cancel {
      margin-left: 33%;
    }

  }

  #form-mensajes {

    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1px;

    .label-input {
      margin-top: 15px;
      font-family: Roboto-Ligth, serif;

    }

    .buttons-navigation {
      margin-top: 15px;
      margin-bottom: 25px;
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 1px;

    }
  }

  @media only screen and (max-width: 576px) {

    #form-liberty {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }


    #form-dictamen {

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;


    }

  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {

    #form-liberty {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {

    #form-liberty {

      padding-left: 20%;
      padding-right: 10%;
      padding-top: 5%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;
    }



  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {


    #form-liberty {

      padding-left: 20%;
      padding-right: 17%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;



      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1px;
      }
    }

    .container-create {
      padding-top: 0%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;
    }




  }

  @media only screen and (min-width: 1200px) {
    #form-liberty {

      padding-left: 17%;
      padding-right: 20%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 17%;
      padding-right: 10%;
      padding-top: 1%;
      width: 100%;
      margin-left: 350px;


      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1px;

      }
    }


    .container-create {
      padding-top: 1%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;

    }
  }

  @media only screen and (min-width: 1200px) and (min-width: 1500px) {
    #form-liberty {

      padding-left: 17%;
      padding-right: 20%;
      padding-top: 1%;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-mensajes {

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1px;

      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;

      }

      .buttons-navigation {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen {

      padding-left: 17%;
      padding-right: 10%;
      padding-top: 1%;
      width: 100%;
      margin-left: 350px;


      .label-input {
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation {

        padding-left: 2%;
        padding-right: 2%;
        padding-top: 1px;

      }
    }



    .container-create {
      padding-top: 1%;
      color: #343741 !important;
      text-align: justify;
      padding-left: 1%;

    }


  }







  .condition-input {
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #919191;
    background-color: #e6e6e6;
    color: #343741 !important;
    margin-bottom: 1px;
  }

}