#modal-list-obligors{


  .list-obligors{
    min-height: 180px;

    .list-group{
      max-height: 250px;
      overflow-y: auto;
    }
    .delete-select-reason{
      cursor: pointer;
      color: black;
    }

  }
  .btns-add-obligor{
    padding-top: 60px;
  }
  .rfc{
    font-family: Roboto-Bold;
    font-size: 11px;
    text-align: center;
  }
  .name{
    font-family: Roboto-Regular;
    font-size: 12px;
    border-left: 1px solid #c0bfc0;
    text-align: left;

  }
}