#authorization-coc-warranty{
  margin-top: 15px;

  .dropdown-toggle{
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;

    border: 1px solid #1A1446!important;
    background-color: #fff!important;
    color: #1A1446!important;
    box-shadow: 0 3px 0 0 #c0bfc0;
  }
}