@import "../../../assets/css/colors";
#expediente-mantenimiento{

  .status-expedient{
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: right!important;
  }


  .card{
    margin-top: 4px;
  }
  .card-header{
    background-color: rgb(245,245,245	)!important;
    color: $primaryText;
    font-family: Roboto-Bold,Arial;
    font-size: 10pt!important;
    padding-top:8px ;
    padding-bottom:5px ;

    .ico-add{
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;

    }
  }
  .card-body {
    padding-top:10px;
    padding-bottom:10px;
  }

  .draggable-true{
    cursor:move;
  }
  .draggable-false{
    cursor:no-drop;
  }

  .name-document{
    font-family: Roboto-Regular,Arial;
    font-size: 10pt!important;
  }


  .actions-expedient{
    .btn{
      background-color: transparent;
      border-color: transparent;
    }
  }
  .drop-area{
    background-color: #F5F5F5;
    font-size: 12px;
  }
  .expedient-files{
    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      cursor: pointer;
      display: inline-block;
      .bg-white {
       // background-color: rgb(169,227,233	)!important;
      }

    }

  }
  @media only screen and (max-width: 720px) {
    .actions-expedient{
      margin-top: 18px;
      background-color: #F5F5F5;
    }

  }
  .list-reject{
    min-height: 250px;
  }



}