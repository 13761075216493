#productos-read-more{

  .read-more-state {
    display: none;
  }

  .read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
    display: none;

  }

  .read-more-state:checked ~ .read-more-wrap .read-more-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
    display: block;
  }

  .read-more-state ~ .read-more-trigger:before {
    content: 'Ver mas...';

  }

  .read-more-state:checked ~ .read-more-trigger:before {
    content: 'Ver menos';
  }

  .read-more-trigger {
    cursor: pointer;
    display: inline-block;
    color: #06748C;
    line-height: 2;
    font-family: 'Roboto', Arial, sans-serif;

  }


}