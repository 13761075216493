@import "../../../assets/css/colors";

.status-expedient{
  margin-top: 5px;
}

.open-expedient{
  cursor: pointer;
}
.expedient-warranty{
  .card-header{
    background-color: $colorPrimaryYellow!important;
    color: $primaryText;
    font-family: Roboto-Bold,Arial;
    font-size: 14pt;
  }
  .card{
    margin-top: 20px;
  }
  .list-group-item{
    cursor:move;
  }
}
.actions-expedient{
  .btn{
    background-color: transparent;
    border-color: transparent;
  }
}
.drop-area{
  background-color: #F5F5F5;
}
@media only screen and (max-width: 720px) {
  .actions-expedient{
    margin-top: 18px;
    background-color: #F5F5F5;
  }

}


.lm-Modal.lm-Modal--large .lm-Modal-wrapper {
  max-width: 1200px;

}
