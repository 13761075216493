#download-reporte-cumulo{
  padding-right: 5px;
  cursor: pointer;
  color: #007bff!important;
}
#spinner-downloading-reporte-cumulo{
  padding-right: 5px;
}
#error-downloading-reporte-cumulo{
  font-family: Roboto;
}