#pipeline{
  .pipeline{
    position: relative;
    z-index: 1;

    .badge{
      font-family: Roboto-Regular,Arial,serif;
      font-size: 17px;
      width: 47px;
      height: 47px;
      padding-block: 14px;
      border: 1px solid #C0BFC0;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .item-name{
      font-family: Roboto-Ligth,Arial,serif;
    }
  }
  .pipe{
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) {
    #hr{
      height:2px;
      width:75%;
      border-width:0;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
    }
  }
}