#form-detail-engagement{

  #form-mensajes{

    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1px;

    .label-input{
      margin-top: 15px;
      font-family: Roboto-Ligth, serif;

    }

    .buttons-navigation{
      margin-top: 15px;
      margin-bottom: 25px;
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 1px;

    }
  }

  @media only screen and  (max-width: 576px) {

    #form-liberty{

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen{

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;

      }
    }

    .container-create{
      padding-top: 0%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;


    }

  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {

    #form-liberty{

      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen{

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    .container-create{
      padding-top: 0%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {

    #form-liberty{

      padding-left: 20%;
      padding-right: 10%;
      padding-top: 5%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen{

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    .container-create{
      padding-top: 0%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;
    }



  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {


    #form-liberty{

      padding-left: 20%;
      padding-right: 17%;
      padding-top: 1%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen{

      padding-left: 17%;
      padding-right: 17%;
      padding-top: 1%;
      width: 100%;



      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    .container-create{
      padding-top: 0%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;
    }




  }

  @media only screen and (min-width: 1200px) {
    #form-liberty{

      padding-left: 17%;
      padding-right: 20%;
      padding-top: 1%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen{

      padding-left: 17%;
      padding-right: 10%;
      padding-top: 1%;
      width: 100%;
      margin-left: 350px;


      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 1px;
        margin-bottom: 25px;
        margin-left: 150px;
      }
    }


    .container-create{
      padding-top: 1%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;

    }
  }

  @media only screen and (min-width: 1200px)  and (min-width: 1500px)  {
    #form-liberty{

      padding-left: 17%;
      padding-right: 20%;
      padding-top: 1%;

      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    #form-dictamen{

      padding-left: 17%;
      padding-right: 10%;
      padding-top: 1%;
      width: 60%;
      margin-left: 350px;


      .label-input{
        margin-top: 15px;
        font-family: Roboto-Ligth, serif;
      }

      .buttons-navigation{
        margin-top: 15px;
        margin-bottom: 25px;
        margin-left: 50px;
        width: 120%;
      }
    }


    .container-create{
      padding-top: 1%;
      color: #343741!important;
      text-align: justify;
      padding-left: 1%;

    }


  }



  .condition-input{
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #919191;
    background-color: #e6e6e6;
    color: #343741!important;
    margin-top: -2px;
    margin-bottom: 1px;
  }


}

